<template>
	<div class="performance performance--overview">
		<number-bars class="number-bars--on-orange number-bars--full-width" :title="`Total \nProcessed Settlement \nValue`" unit="( in billions )" :numbers="totalProcessedSettlement" />
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'InsuranceRetirement',
	components: {
		NumberBars,
	},
	data: () => ({
		totalProcessedSettlement: [
			{
				value: 136.1,
				label: '2020',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				value: 124.30,
				label: '2019',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				value: 109.30,
				label: '2018',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
		],
	})
}
</script>

<style scoped lang="scss">

</style>
